import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class SliderGuard implements CanActivate {
	constructor(
		private router: Router, 
		private storage: Storage) { } 

	async canActivate() {
		console.log("slider gaurd is working");
		const slider_result_data = await this.storage.get('is_slider');
		if (!slider_result_data) {
			this.router.navigate(["/slider"]);
			return false;
		}
		return true;
	}
}
