import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CurrentlocationPageRoutingModule } from './currentlocation-routing.module';
import { CurrentlocationPage } from './currentlocation.page';
import { AgmCoreModule } from '@agm/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD_J6ltB_3ZzYnRq03p_FkUAKKBDv6QjXs',
      /* apiKey is required, unless you are a 
      premium customer, in which case you can 
      use clientId 
      */
     libraries: ['geometry', 'places']
    }),
    CurrentlocationPageRoutingModule

  ],
  declarations: [CurrentlocationPage]
})
export class CurrentlocationPageModule {}
