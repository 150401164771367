import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckloginGuard implements CanActivate {
	constructor(
		private router: Router, 
		private storage: Storage) { }

	async canActivate() {
		console.log("checklogin gaurd is working");

		const rider_id_data = await this.storage.get('rider_id');
		if (!rider_id_data) {
			this.router.navigate(["/auth/landing"]);
			return false;
		}

    const rider_result_data = await this.storage.get('rider_result');
		if (!rider_result_data) {
			this.router.navigate(["/auth/landing"]);
			return false;
		}

    const rider_token_data = await this.storage.get('rider_token');
		if (!rider_token_data) {
			this.router.navigate(["/auth/landing"]);
			return false;
		}

		return true;
	}
}
