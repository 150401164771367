import { ChangeDetectorRef, Component } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Platform, NavController, AlertController, MenuController, ModalController, LoadingController } from '@ionic/angular';
import BackgroundGeolocation, {
  State,
  Config,
  Location,
  LocationError,
  Geofence,
  HttpEvent,
  MotionActivityEvent,
  ProviderChangeEvent,
  MotionChangeEvent,
  GeofenceEvent,
  GeofencesChangeEvent,
  HeartbeatEvent,
  ConnectivityChangeEvent
} from "cordova-background-geolocation-lt";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { environment } from 'src/environments/environment';
import { AlertService } from './services/alert.service';
import { NavigationEnd, Router } from '@angular/router';
import { AnimationOptions } from '@ionic/angular/providers/nav-controller';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { AngularFireMessaging } from '@angular/fire/messaging';

// import { } from '../assets/'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  active_app_android_version: any;
  active_app_ios_version: any;  
  
  audio: HTMLAudioElement = new Audio();

  rider_fcmtoken:string = '';
  fcmtoken_type:string = '';
  user_name:string;

  rider_id: number = 0;
  rider_result: any;
  rider_token: string;

  rider_notification_subscription:number = 0;
  public history: string[] = [];

  ordermessage_blockshow:boolean = false;
  ordermessage_title:string;
  ordermessage_description:string; 

  backButtonSubscription;
  

  public appPages = [
    { title: 'General', url: '/general', icon: 'paper-plane' },
    { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];

  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  

  active_platform:string = '';

  constructor(private storage:Storage,
    private platform: Platform,
    private fcm: FCM,
    private http: HttpClient,
    private navCtrl: NavController,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private alertService: AlertService,
    private router: Router,
    private alertController: AlertController,
    private clipboard: Clipboard,
    private afMessaging: AngularFireMessaging,
    private detectChanges: ChangeDetectorRef) {
    this.storage.get('rider_name').then((val) => {
      if(val !== undefined && val !== null) {
        this.user_name = val;
      }
    });
    this.initializeApp();
    this.setupStorageVariables();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
    this.active_app_android_version = parseInt(environment.active_app_android_version);
    this.active_app_ios_version = parseInt(environment.active_app_ios_version);
  }

  initializeApp() {

    this.platform.ready().then(() => {

      this.audio.src = '/assets/notification.mp3';
      this.audio.load();

      if(this.platform.is('android')) { this.active_platform = 'android'; }
      if(this.platform.is('ios')) { this.active_platform = 'ios'; }

      if(this.platform.is('capacitor')) {
      
          if (this.active_platform == 'android') {

            // this.statusBar.styleBlackOpaque();
            //   this.fcm.createNotificationChannel({
            //     description: 'General Notifications',
            //     id: 'fcm_default_channel',
            //     importance: 'high',
            //     lights: true,
            //     name: 'my_notification_channel',
            //     sound: 'bell.mp3',
            //     vibration: true,
            //     visibility: 'public'
            //   }).then(() => {
            //     console.log('push channel created: ');
            //   }).catch(error =>{
            //     console.error('push channel error: ', error);
            // });

            // this.fcm.createNotificationChannel({
            //   id: "urgent_alert", // required
            //   name: "Urgent Alert", // required
            //   description: "Very urgent message alert",
            //   importance: "high", // https://developer.android.com/guide/topics/ui/notifiers/notifications#importance
            //   visibility: "public", // https://developer.android.com/training/notify-user/build-notification#lockscreenNotification
            //   sound: "bell", // In the "alert_sound" example, the file should located as resources/raw/alert_sound.mp3
            //   lights: true, // enable lights for notifications
            //   vibration: true // enable vibration for notifications
            // });
          }

          // this.fcm.getToken().then(token => {
          //   this.rider_fcmtoken = token;          
          //   //console.log(token);          
          // });

          // this.generalService.getShop();
          // this.generalService.getSignature();
          // this.fcm.onNotification().subscribe(data => {

          //   if(data.wasTapped) {
          //     // noPlay
          //   } else {
          //     this.audio.play();
          //   };

          //   if(data.tickerText == 'callrider') {
          //     this.orderpresentAlert(data.title, data.body);
          //   } else {
          //     this.presentAlert(data.title, data.body);
          //   }

          // });   
      } else{

        this.afMessaging.requestToken.subscribe((token) => {
            if(token) {
              this.rider_fcmtoken = token;
            } else {
              this.rider_fcmtoken = '';
            }
            this.fcmtoken_type = 'web';
            console.log('web-token', this.rider_fcmtoken)
          },
          (err) => {
            console.error('Unable to get permission to notify.', err);
          }
        );

        this.afMessaging.messages.subscribe((notification) => {
          console.log(notification);
          let data = notification['data'];
          if(data.tickerText == 'callrider') {
            this.orderpresentAlert(data.title, data.body);
          } else {
            this.presentAlert(data.title, data.body);
          } 
       
          //console.log('Push action performed: ' + JSON.stringify(notification));
        });

      }
    });
  }


  redirectURL(_oUrl:string) {
    this.navCtrl.navigateRoot(_oUrl);
  }


  call_backlink(): void {
    this.modalController.getTop().then((modalDetail) => {
      if (modalDetail) {
        this.modalController.dismiss();
      } else {
        this.history.pop()
        if (this.history.length > 0) {
          //this.location.back()
          //this.navCtrl.back();
          let animations:AnimationOptions={
            animated: true,
            animationDirection: "back"
          }
          this.navCtrl.back(animations);
        } else {
          this.router.navigateByUrl('/');
        }
      }
    });
  }


  openDashboard() {
    console.log("open dashboard link");
    this.router.navigateByUrl('dashboard');
  }


  openScheduleHours() {
    console.log("open dashboard link");
    this.router.navigateByUrl('schedule');
  }


  async setupStorageVariables() {
    this.storage.get('rider_id').then((data) => {
      if (data !== null && data !== undefined) {
        this.rider_id = data;
      } else {
        this.rider_id = 0;
      }
    });
    this.storage.get('rider_result').then((data) => {
      if (data !== null && data !== undefined) {
        this.rider_result = data;

        if(this.rider_result.rider_status == 1 && this.rider_result.rider_active == 1 && this.rider_result.rider_approved == 1 && this.rider_result.rider_bglocationallow == 1) {
          this.triggerlocation(1);
        } else {
          this.triggerlocation(0);
        }
      } else {
        this.triggerlocation(0);
      }
    });
    this.storage.get('rider_token').then((data) => {
      if (data !== null && data !== undefined) {
        this.rider_token = data;
      }
    });
  }


  async changeStatus(rider_status:number, latitude:any, longitude:any) {
    const loading = await this.loadingController.create({
      cssClass: 'loadingScreen',
      spinner: null,
      translucent: true,
      showBackdrop: false,
      message: `<div class="_cLoading"><ion-img src="./assets/loading-logo.gif"></ion-img></div>`,
    });
    loading.present();
    const formData = new FormData();
    formData.append('rider_id', this.rider_id.toString());
    formData.append('change_status', '1');
    formData.append('rider_status', rider_status.toString());
    
    formData.append('riderstatuslog_lat', latitude);
    formData.append('riderstatuslog_lag', longitude);

    let headers = new HttpHeaders({ 'accept': "application/json", 'Authorization': this.rider_token });
    this.http.post(environment.API_URL+'raction', formData, { headers: headers }).subscribe(
      (response: Response) => {
        this.storage.set('rider_result', response['rider_result']).then(() => {
          this.setupStorageVariables();
          loading.dismiss();
        });
      },
      error => {
        loading.dismiss();
        console.log( error );
        console.log( error.error );
        if( error.error.messagedetail == undefined ) {
            this.alertService.presentToast("Error to connect..");
        } else {
            this.alertService.presentToast(error.error.messagedetail);
        }
      }
    );
  }


  triggerlocation(rider_status:number) {
    // console.log('triggerlocation');
    // console.log(rider_status);

    // BackgroundGeolocation.onLocation(location => {
    //   const formData = new FormData();
    //   formData.append('rider_id', this.rider_id.toString());
    //   formData.append('rider_updatelocation', '1');
    //   formData.append('rider_lat', location.coords.latitude.toString());
    //   formData.append('rider_lag', location.coords.longitude.toString());
      
    //   let headers = new HttpHeaders({ 'accept': "application/json", 'Authorization': this.rider_token });
    //   this.http.post(environment.API_URL+'raction', formData, { headers: headers }).subscribe(
    //     () => {},
    //     error => {
    //       console.log('error error error error error error error');
    //       console.log(error);
    //     }
    //   )
    // }, error => {
    //   console.log("[location] ERROR: ", error);
    // });

    // BackgroundGeolocation.ready({desiredAccuracy: 0, distanceFilter: 50}).then(state => {
    //   console.log('bg READY....');
    //   if(rider_status == 1) {
    //     if(!state.enabled) {
    //       BackgroundGeolocation.start(function() {
    //         console.log("- Start success");
    //       });
    //       console.log('START');
    //     }
    //   } else {
    //     if(state.enabled) {
    //       BackgroundGeolocation.stop(function() {
    //         console.log("- Stop success");
    //       });
    //     }
    //   }
    // }).catch(error => {
    //   console.log('- BackgroundGeolocation error: ', error);
    // });
  }


  async logout() {
    const loading = await this.loadingController.create({
      cssClass: 'loadingScreen',
      spinner: null,
      translucent: true,
      showBackdrop: false,
      message: `<div class="_cLoading"><ion-img src="./assets/loading-logo.gif"></ion-img></div>`,
    });
    loading.present();
    const formData = new FormData();
    formData.append('rider_id', this.rider_id.toString());
    formData.append('rider_fcmtoken', this.rider_fcmtoken);
    let headers = new HttpHeaders({ 'accept': "application/json", 'Authorization': this.rider_token });
    this.http.post(environment.API_URL+'logout', formData).subscribe(
      () => {
        this.storage.remove('rider_id');
        this.storage.remove('rider_result');
        this.storage.remove('rider_token');
        this.setupStorageVariables();
        this.router.navigateByUrl('/auth/landing');
        loading.dismiss();
      },
      error => {
        loading.dismiss();
        console.log(error);
        console.log(error.error);
        if( error.error.messagedetail == undefined ) {
            this.alertService.presentToast("Error to connect..");
        } else {
            this.alertService.presentToast(error.error.messagedetail);
        }
      }
    );
  }


  async presentAlert(title:string, body:string) {
    let alert = await this.alertController.create({
      subHeader: title,
      message: body,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            console.log('Ok clicked');
          }
        },
      ]
    });  
    await alert.present();
    setTimeout(()=>{
      alert.dismiss();
    }, 100000);
  }


  async orderpresentAlert(title:string, body:string) {
    this.ordermessage_blockshow = true;
    this.ordermessage_title = title;
    this.ordermessage_description = body;
    this.detectChanges.detectChanges();
  } 


  close_ordermessageblock() {
    this.ordermessage_blockshow = false;
    this.ordermessage_title = '';
    this.ordermessage_description = '';
  }


  open_orderdetailpage() {
    this.close_ordermessageblock();
    let uniqID = this.getUniqueId(4);
    this.navCtrl.navigateRoot('/dashboard/'+uniqID);
  }


  get_maplink(_qLat:any, _qLag:any) {
    return 'https://maps.google.com/?q='+_qLat+','+_qLag;
  }


  get_whatapplink(cNumber:string) {
    return 'https://api.whatsapp.com/send?phone='+cNumber;
  }


  getUniqueId(parts: number): string {
    const stringArr = [];
    for(let i = 0; i< parts; i++){
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }


  numberIntoVersion(_vNumber:any) {
    return _vNumber;
  }


  set_currencySymbol(country_id:any) {
    if(country_id == 167) {
      return 'PKR';

    } else  if (country_id == 211) {
      return 'SDG';

    } else  if (country_id == 209) {
      return 'EUR';

    } else  if (country_id == 165) {
      return 'kr';

    } else  if (country_id == 194) {
      return 'SAR';

    } else {
      return '';
    }
  }


  copyText(_cTxt:any) {
    this.clipboard.copy(_cTxt);
    this.alertService.successToast('Payment reference copied.');
  }


  record_validation(value:any){
    return value !== null && value !== undefined && value !== '' &&  value !== 'null';
  }

}
