/// <reference types="@types/googlemaps" />
import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-currentlocation',
  templateUrl: './currentlocation.page.html',
  styleUrls: ['./currentlocation.page.scss'],
})
export class CurrentlocationPage implements OnInit {

  rider_allowlag: number;
  rider_allowlat: number;
  rider_allowradius: number;

  default_latitude:number = 31.4234953;
  default_longitude:number = 72.9492158;

  constructor(
    public app: AppComponent,
    private storage: Storage,
    private alertService: AlertService,
    private loadingController: LoadingController,
    private modalController: ModalController,
    ) {}

  ngOnInit() {
    this.storage.get('rider_token').then((data) => {
      this.app.setupStorageVariables();
      this.setup();
    });
  }

  setup() {
    /*
    //this.rider_allowradius = parseFloat(this.app.rider_result['rider_allowradius']);
    console.log( " + setup + " );
    console.log( this.app.rider_result );
    console.log( this.app.rider_result['rider_allowlat'] );
    console.log( this.app.rider_result['rider_allowlag'] );
    console.log( this.app.rider_result['rider_allowradius'] );
    console.log(parseFloat(this.app.rider_result['rider_allowradius']) * 1000);
    */
  }

  close() {
    this.modalController.dismiss();
  }

}
