import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CheckloginGuard } from './guard/checklogin.guard';
import { SliderGuard } from './guard/slider.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule),

  },
  {
    path: 'general',
    loadChildren: () => import('./pages/general/general.module').then( m => m.GeneralPageModule),
  },
  {
    path: 'slider',
    loadChildren: () => import('./pages/slider/slider.module').then( m => m.SliderPageModule)
  },
  {
    path: 'auth/landing',
    loadChildren: () => import('./pages/auth/landing/landing.module').then( m => m.LandingPageModule),
    canActivate: [SliderGuard]
  },
  {
    path: 'auth/login/logincountry',
    loadChildren: () => import('./pages/auth/logincountry/logincountry.module').then( m => m.LogincountryPageModule),
    canActivate: [SliderGuard]
  },
  {
    path: 'auth/login/loginaddprofile',
    loadChildren: () => import('./pages/auth/loginaddprofile/loginaddprofile.module').then( m => m.LoginaddprofilePageModule),
    canActivate: [SliderGuard]
  },
  {
    path: 'auth/login/loginverify',
    loadChildren: () => import('./pages/auth/loginverify/loginverify.module').then( m => m.LoginverifyPageModule),
    canActivate: [SliderGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [SliderGuard, CheckloginGuard]
  },
  {
    path: 'dashboard/:time',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [SliderGuard, CheckloginGuard]
  },
  {
    path: 'schedule',
    loadChildren: () => import('./pages/schedule-hours/schedule-hours.module').then( m => m.ScheduleHoursPageModule),
    canActivate: [SliderGuard, CheckloginGuard]
  },
  {
    path: 'auth/register/registercountry',
    loadChildren: () => import('./pages/auth/registercountry/registercountry.module').then( m => m.RegistercountryPageModule),
    canActivate: [SliderGuard]
  },
  {
    path: 'auth/register/registeraddprofile',
    loadChildren: () => import('./pages/auth/registeraddprofile/registeraddprofile.module').then( m => m.RegisteraddprofilePageModule),
    canActivate: [SliderGuard]
  },
  {
    path: 'auth/register/registerverify',
    loadChildren: () => import('./pages/auth/registerverify/registerverify.module').then( m => m.RegisterverifyPageModule),
    canActivate: [SliderGuard, CheckloginGuard]
  },
  {
    path: 'auth/complete',
    loadChildren: () => import('./pages/auth/complete/complete.module').then( m => m.CompletePageModule),
    canActivate: [SliderGuard, CheckloginGuard]
  },
  {
    path: 'completeapplication',
    loadChildren: () => import('./pages/apply/completeapplication/completeapplication.module').then( m => m.CompleteapplicationPageModule),
    canActivate: [SliderGuard, CheckloginGuard]
  },
  {
    path: 'orderdetail',
    loadChildren: () => import('./pages/raction/orderdetail/orderdetail.module').then( m => m.OrderdetailPageModule),
    canActivate: [SliderGuard, CheckloginGuard]
  },
  {
    path: 'orderdetail/:quotation_id',
    loadChildren: () => import('./pages/raction/orderdetail/orderdetail.module').then( m => m.OrderdetailPageModule),
    canActivate: [SliderGuard, CheckloginGuard]
  },
  {
    path: 'stats',
    loadChildren: () => import('./pages/stats/stats.module').then( m => m.StatsPageModule),
    canActivate: [SliderGuard, CheckloginGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'currentlocation',
    loadChildren: () => import('./popup/currentlocation/currentlocation.module').then( m => m.CurrentlocationPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
