// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,
  API_URL: "https://apps.foodsted.com/api/rider/aa/api/",

  // API_URL: "http://192.168.0.160:8080/laravel/api/rider_aa/public/api/",
  //API_URL: "http://127.0.0.1:8080/laravel/api/rider_aa/public/api/",
  //API_URL: "http://192.168.0.106:8080/laravel/api/rider_aa/public/api/",
  //API_URL: "http://192.168.100.10:8080/laravel/api/rider_aa/public/api/",
  //API_URL: "http://127.0.0.1:8080/laravel/api/rider_aa/public/api/register_token?rider_name=Arfan Akram&rider_token=TokenTokenToken6",

  active_app_android_version: "108_i",
  active_app_ios_version: "108_i",
  download_android_app_version: "https://foodsted.com/rider",

  firebase: {
    apiKey: "AIzaSyDyMOMwMtoFUkHPT7ghquPap5nEWv9y3YI",
    authDomain: "foodsted-5a614.firebaseapp.com",
    projectId: "foodsted-5a614",
    storageBucket: "foodsted-5a614.appspot.com",
    messagingSenderId: "311127017290",
    appId: "1:311127017290:web:e517670330dd57d899e3b3",
    measurementId: "G-GW8ZL4WMLB"
  },
  
};