import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import BackgroundGeolocation from "cordova-background-geolocation-lt";
import { IonicStorageModule } from '@ionic/storage';
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { CurrentlocationPageModule } from './popup/currentlocation/currentlocation.module';
import { QRCodeModule } from 'angularx-qrcode';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD_J6ltB_3ZzYnRq03p_FkUAKKBDv6QjXs',
    }),
    CurrentlocationPageModule,
    QRCodeModule,
    ServiceWorkerModule.register('combined-sw.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    FCM,
    BackgroundGeolocation,
    AppComponent,
    CallNumber,
    Clipboard,
    Geolocation
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
